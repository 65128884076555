import * as React from 'react'
import { useProgress } from './Scrollable'
import { useIntroContext } from './IntroContext'
import { motion, useMotionValue } from 'framer-motion'

export const Cursor = (props: CursorProps) => {

  const y = useMotionValue(-9000)
  const x = useMotionValue(-9000)

  const { paused } = useProgress()
  const { showLogo, showCursor, setShowCursor } = useIntroContext()
  const [forceHideCursor, setForceHideCursor] = React.useState(false)

  React.useEffect(() => {

    if (!window || !showLogo) return

    const onPointerMove = (e: PointerEvent) => {

      setForceHideCursor(false)

      if (e.pointerType === 'mouse') {
        x.set(`calc(-50% + ${ e.x }px)`)
        y.set(`calc(-50% + ${ e.y }px)`)
      }
    }

    const onPointerLeave = (e: PointerEvent) => {
      setForceHideCursor(true)
      console.log('mouse out')
    }

    document.addEventListener('pointermove', onPointerMove)
    document.addEventListener('pointerout', onPointerLeave)

    return () => {
      document.removeEventListener('pointermove', onPointerMove)
      document.removeEventListener('pointerout', onPointerLeave)
    }

  }, [showLogo])

  return (
    <motion.p
      style={{
        x,
        y,
        visibility: (!showCursor || forceHideCursor) ?
          'hidden' : 'visible'
      }}
      className="pause-play-cursor">

      {paused ? 'Play' : 'Pause'}

    </motion.p>
  )

}