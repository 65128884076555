import * as React from 'react'
import { useProgress } from './Scrollable'
import { useIntroContext } from 'components/IntroContext'
import { motion } from 'framer-motion'

export const PausePlay = () => {

  const { isAbout, showLogo, setShowCursor } = useIntroContext()
  const { togglePause, paused } = useProgress()

  const headerStyle = {
    opacity: (isAbout || showLogo) ? 1 : 0,
  }

  const headerAnimate = {
    ...headerStyle,
    transition: {
      ease: 'linear',
      delay: (isAbout || showLogo) ? 0.4 : 0
    }
  }

  return (
    <motion.button
      id="pausePlay"
      onMouseEnter={() => {
        setShowCursor(false)
      }}
      onMouseLeave={() => {
        setShowCursor(true)
      }}
      onPointerUp={togglePause}
      className="pause-play"
      initial={headerStyle}
      animate={headerAnimate}
    >
      {paused ? 'Play' : 'Pause'}
    </motion.button>
  )

}
