import * as React from 'react'
import { useRef, useState, useEffect, useContext, createContext } from 'react'
import { animate, useTransform, motionValue, useMotionValue, motion } from 'framer-motion'
import { useWindowSize } from './WindowSizeContext'
import { transform, physics } from 'popmotion'
import portraitProjects from '../js/source/portrait'
import landscapeProjects from '../js/source/landscape'

const Progress = createContext({
  progress: motionValue(0),
  resumePlayProgress: () => {},
  pausePlayProgress: () => {},
  unpausePlayProgress: () => {},
  startPlayProgress: () => {},
  resetPlayProgress: () => {},
  play: false,
  paused: false
})

export const useProgress = () => {
  return useContext(Progress)
}

export const Scrollable = props => {

  const [meta, setMeta] = useState(null)
  const isTouch = useRef(false)
  const wrap = transform.wrap(0, 1)
  const controls = useRef({ stop: () => {} })
  const timer = useRef()
  const playProgress = useMotionValue(0)
  const scrollYProgress = useMotionValue(0)
  const { winHeight, orientation } = useWindowSize()

  const [play, setPlay] = useState(false)
  const [allowInput, setAllowInput] = useState(false)
  const [paused, setPaused] = useState(false)

  // Get current meta object

  const resetPlayProgress = () => {

    setPlay(false)
    setPaused(false)

    if (controls.current) {
      controls.current.stop()
    }

    playProgress.set(0)

  }

  const pausePlayProgress = () => {
    setPaused(true)
  }

  const unpausePlayProgress = () => {
    setPaused(false)
  }

  const startPlayProgress = () => {
    setPlay(true)
    setAllowInput(true)
  }

  const resumePlayProgress = unpause => {
    if (!allowInput) return
    setPlay(true)
    if (unpause) setPaused(false)
  }

  useEffect(() => {

    if (meta && play && !paused) {

      const next = playProgress.get() + 1
      const duration = props.duration || meta.reduce((d, m) => d + m.duration / 1000, 0)

      controls.current = animate(playProgress, next, {
        duration,
        repeat: Infinity,
        type: 'tween',
        ease: 'linear'
      })

    } else {

      if (controls.current) {
        controls.current.stop()
      }

    }

  }, [meta, paused, play])

  const handlePointerDown = e => {
    if (e.target.id === 'pausePlay') return
    setPlay(false)
  }

  const handlePan = (_, i) => {

    if (!meta) return
    if (!allowInput) return

    const inc = - i.delta.y / (winHeight * meta.length)
    const next = scrollYProgress.get() + inc

    scrollYProgress.set(next)

  }

  const handleMouseUp = (e) => {

    if (e.target.id === 'pausePlay') return

    togglePause()

  }

  const handlePointerUp = (e, i) => {

    if (e.target.id === 'pausePlay') return

    if (!allowInput) return
    if (!orientation) return

    physics({
      from: scrollYProgress.get(),
      velocity: scrollYProgress.getVelocity(),
      friction: 0.3,
      restSpeed: 1
    }).start({
      update(v) {
        scrollYProgress.set(v)
      },
      complete() {
        resumePlayProgress()
      }
    })

  }

  const handleWheel = e => {

    if (!allowInput) return
    if (!orientation) return

    if (!isTouch.current && play) {
      setPlay(false)
      clearTimeout(timer.current)
      timer.current = setTimeout(resumePlayProgress, 50)
    }

    const inc = e.deltaY / (winHeight * meta.length)
    const next = scrollYProgress.get() + inc

    scrollYProgress.set(next)

  }

  // Decide meta

  useEffect(() => {

    if (orientation == 'portrait') {
      setMeta(portraitProjects)
    }

    if (orientation == 'landscape') {
      setMeta(landscapeProjects)
    }

  }, [orientation])

  // Event handlers for wheel

  useEffect(() => {

    window.addEventListener('wheel', handleWheel)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('pointerup', handlePointerUp)
    document.addEventListener('pointerdown', handlePointerDown)

    return () => {
      window.removeEventListener('wheel', handleWheel)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('pointerup', handlePointerUp)
      document.removeEventListener('pointerdown', handlePointerDown)
    }

  })

  const progress = useTransform([
    playProgress,
    scrollYProgress
  ], ([play, scroll]) => {
    return wrap(play + scroll)
  })

  const togglePause = e => {
    if (paused) {
      resumePlayProgress(true)
    } else {
      pausePlayProgress()
    }
  }

  return (
    <Progress.Provider
      value={{
        play,
        paused,
        progress,
        unpausePlayProgress,
        pausePlayProgress,
        resumePlayProgress,
        startPlayProgress,
        togglePause,
        resetPlayProgress
      }}
    >

      <motion.div onPan={handlePan} className="container">
        {props.children}
      </motion.div>

    </Progress.Provider>
  )

}
