import * as React from 'react'
import { useState, useLayoutEffect, useContext, createContext } from 'react'
import { debounce } from 'lodash'

const WindowSizeContext = createContext({
  winWidth: 0,
  winHeight: 0,
  isSet: false,
  isPortrait: false,
  isLandscape: false
})

export const useWindowSize = () => {
  return useContext(WindowSizeContext)
}

export const WindowSizeProvider = ({ children }) => {

  const [windowSize, setWindowSize] = useState({
    winWidth: 0,
    winHeight: 0,
    orientation: null
  })

  useLayoutEffect(() => {

    // Get max height

    const checkDimensions = state => {

      const div = document.createElement('div')

      div.classList.add('height-check')
      document.body.appendChild(div)

      const winHeight = div.clientHeight
      const winWidth = document.documentElement.clientWidth || document.body.clientWidth
      const orientation = winHeight > winWidth ? 'portrait' : 'landscape'

      document.body.removeChild(div)

      return {
        winWidth,
        winHeight,
        orientation
      }

    }

    const setSize = debounce(() => {
      setWindowSize(state => checkDimensions(state))
    }, 300)

    setSize()

    window.addEventListener('resize', setSize)

    return () => {
      window.removeEventListener('resize', setSize)
    }

  }, [])

  return (
    <WindowSizeContext.Provider value={windowSize}>
      { children }
    </WindowSizeContext.Provider>
  )

}
