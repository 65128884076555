import { useRef, useState, useEffect } from 'react'
import { debounce } from 'lodash'
import stylefire from 'stylefire'

export const useStyler = () => {

  const ref = useRef()
  const [styler, setStyler] = useState()

  useEffect(() => {
    const s = stylefire(ref.current)
    setStyler(s)
  }, [])

  return [ref, styler]

}

export const useSize = () => {

  const [size, setSize] = useState(null)

  useEffect(() => {

    const checkDimensions = () => {

      const div = document.createElement('div')

      div.classList.add('height-check')
      document.body.appendChild(div)

      const winHeight = div.clientHeight
      const winWidth = document.documentElement.clientWidth || document.body.clientWidth

      document.body.removeChild(div)

      return {
        winWidth,
        winHeight
      }

    }

    const setWindowSize = debounce(() => {
      setSize({ ...checkDimensions() })
    }, 300)

    setWindowSize()

    window.addEventListener('resize', setWindowSize)

    return () => {
      window.removeEventListener('resize', setWindowSize)
    }

  }, [])

  return {
    size
  }

}
